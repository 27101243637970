<template>
  <div class="home">
    <div class="content">
      <div class="main">
        <div class="main_bg">
          <div class="search">
            <van-swipe :autoplay="3000" lazy-render class="my-swipe">
              <van-swipe-item v-for="(i, index) in homeData.swipe_list" :key="index">
                <img :src="i.pic" class="swipe_src" @click="onChange(i)" />
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
        <div class="van-tabs__repalce"></div>
        <van-tabs
          class="van-tabs"
          color="#fff"
          line-width="80px"
          background="#211E20"
          title-inactive-color="#808080"
          title-active-color="#fff"
        >
          <van-tab
            v-for="(i, index) in homeData.centent_nav_list"
            :title="i.tab"
            :key="index"
          >
            <Store :store_list="i.data" />
          </van-tab>
        </van-tabs>
      </div>
      <a class="bah" href="https://beian.miit.gov.cn/" target="_blank">备案号:赣ICP备2020012555号-4</a>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
import Store from "./components/Store.vue";
import { onMounted, reactive, toRefs } from "vue";
import { getHomeData } from "../../request/api";
import { Toast } from "vant";
import axios from "../../request/request";
import { useRouter } from "vue-router";
export default {
  components: {
    Footer,
    Store,
  },
  setup() {
    const router = useRouter();
    const onChange = (item) => {
      // this.id = id;
      console.log("id--" + item.id);
      console.log("id--" + item.jumpType);
      if (item.jumpType === 0) {
        console.log("id-可跳转-");
        router.push({ path: "./swipe", query: { mid: item.id } });
      }
    };

    let data = reactive({
      homeData: {},
      vanActive: false,
      id:null,
      mobile: null,   
    });
    //数据请求
    // const getHome = async () => {
    //   const res = await getHomeData();
    //   if (res.status === 200 && res.data.code === 0) {
    //     data.homeData = res.data.data;
    //   }
    // };
    const scrollHandle = () => {
      const tabsElement = document.querySelector(".van-tabs");
      const tabsWrap = document.querySelector(".van-tabs__wrap");
      const contentElement = document.querySelector('.content')
      contentElement.addEventListener(
        "scroll",
        () => {
          let tabsScrooTop = tabsElement.offsetTop;
          const scrollTop = contentElement.scrollTop || window.pageYOffset || document.body.scrollTop
          if (scrollTop >= tabsScrooTop - 20) {
            if (tabsWrap.className.indexOf("active") === -1) {
              tabsWrap.className += " active";
            }
          } else {
            if (tabsWrap.className.indexOf("active") > -1) {
              tabsWrap.className = tabsWrap.className.replace("active", "");
            }
          }
        },
        true
      );
    }
    onMounted(() => {
    //  getHome();
      scrollHandle()
    });
    return {
      onChange,
      router,
      ...toRefs(data),
    };
  },
  methods: {
    // 请求openid
getHome() {
           var THIS = this;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      axios
        .post("app/home", {
          ch: JSON.parse(window.sessionStorage.getItem("ch")),
        })
        .then(function (res) {
          console.log(res);
          console.log(res.status);
                 if (res.status === 200 && res.data.code === 0) {
                           Toast.clear();
                  THIS.homeData = res.data.data;
                   } 
               
             
        axios
        .post("app/addShare", {
          bd: JSON.parse(window.sessionStorage.getItem("bd")),
        })
        .then(function (res) {
        }).catch(function (error) {
        
        });



        })
        .catch(function (error) {
          // 请求失败处理
           Toast.fail("请检查网络");
             console.log(error);
           // THIS.router.push({ path: "./swipe" });
        });
    },
    getopenid_data(code, sp) {
           var THIS = this;
      console.log("code--" + code + "-sp--" + sp);
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      axios
        .post("app/getUserCode", {
          code: code,
          sp: sp,
        })
        .then(function (res) {
          console.log(res);
          console.log(res.status);
          console.log(res.data.retCode);

          if (res.status === 200 && res.data.retCode === 0) {
            Toast.clear();
            window.sessionStorage.setItem("userOpenid", JSON.stringify(res.data.data));
          } else {
            // Toast.fail("获取用户信息失败");
          }
       
           if(sp !== "state"){
            //跳转到分享页
            //shareGoodsInfo
              THIS.router.push({ path: "./shareGoodsInfo", query: { state: sp } });
           }
        })
        .catch(function (error) {
          // 请求失败处理
           Toast.fail("请检查网络");
           // THIS.router.push({ path: "./swipe" });
        });
    },

    addLogin_data() {

    
      var THIS = this;
      const userOpenid = JSON.parse(
        window.sessionStorage.getItem("userOpenid")
      );
       if (userOpenid != null) {
           THIS.id =  userOpenid.id;
           THIS.mobile =  userOpenid.mobile;
       }
      axios
        .post("app/appLoginHistory", {
           id: THIS.id,
          mobile: THIS.mobile ,
        })
        .then(function (res) {
          console.log(res);
        })
        .catch(function (error) {
        
        });
    },
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let url = window.location.href.split("#")[0];
      let search = url.split("?")[1];
      if (search) {
        var r = search.substr(0).match(reg);
        if (r !== null) return unescape(r[2]);
        return null;
      } else {
        return null;
      }
    },
     isMobileDevice() {//判断当前设备是否为移动端
      const ua = navigator.userAgent.toLowerCase();
      const t1 = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          ua
      );
       console.log("t1---" +t1);
            console.log("ua---" +ua);
      const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1;
        console.log("t2---" +t2);
      return t1 || t2;
    },
  },
  created: function () {
        console.log("---" + sp);
          var ch = this.getUrlParam("ch");
            console.log("ch1----" + ch);
                 if (ch == null) {
                       ch = "0";
                  }
          window.sessionStorage.setItem("ch", JSON.stringify(ch));

         var bd = this.getUrlParam("bd");
            console.log("bd----" + bd);
                 if (bd == null) {
                       bd = "0";
                  }
          window.sessionStorage.setItem("bd", JSON.stringify(bd));

         const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));
       var ua = navigator.userAgent.toLowerCase(); 
    if(ua.match(/MicroMessenger/i) != "micromessenger"){
        const localUserOpenid = window.localStorage.getItem("userOpenid");
         if (localUserOpenid == null) {
              this.router.push({ path: "./login" });
             }else{
               window.sessionStorage.setItem("userOpenid", localUserOpenid)
                console.log(userOpenid);
                   this.addLogin_data();
             }
    }else{
 // Toast("这里啦--");

    //  console.log(userOpenid.openid);
    // if(userOpenid == null){
    var code = this.getUrlParam("code");
    var state = this.getUrlParam("state");
    var sp = this.getUrlParam("sp");
  
    console.log("sp1----" + sp);
  
    if (sp == null) {
      sp = "state";
    }
    console.log("sp2----" + sp);
    console.log("state11----" + state);
    var local = window.location.href;
    if ((code == null || code == "") && process.env.NODE_ENV === "production") {
      // Toast("code为空--");
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx23454132f087d21b&redirect_uri=" +
        encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_base&state=" +
        sp +
        "#wechat_redirect";
    } else {
      this.code = code;
      //Toast("code--" + code);
      //获取openid
      const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));
      console.log("code--" + code);
      console.log("state--" + state);
      //  if(userOpenid === null ){
      this.getopenid_data(code, sp);
      // }  
    }

    //this.router.push({ path: "./shareGoodsInfo", query: { state: '4q9nkrrtTLH%2FrzKmAaW5nQ%3D%3D' } });

    // }
    //  if(userOpenid == null){
    //  this.getopenid_data("0016uMFa1gyU3D00LgHa1NuXwI36uMFN")
    // }
    }
        this.getHome();
  },
};
</script>

<style lang="less" scoped>
.my-swipe {
  height: 120px;
  border-radius: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  .swipe_src {
    height: 120px;
    width: 100%;
  }
}

/deep/ .van-tabs__wrap {
  //   border-radius: 10px;
  color: #ffc400;
  position: absolute;
  top: -1.7333rem;
  left: 0;
  width: 100%;
  &.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
  }
}

.home {
  display: flex;
  flex-flow: column;
  background: rgba(33, 30, 32, 1);
  height: 100%;
  overflow: auto;
  .content {
    flex: 1;
    overflow: auto;
    .main {
      margin-top: 10px;
      .van-tabs {
        padding: 0 10px 10px;
      }
      .van-tabs__repalce {
        height: 1.17333rem
      }
      .main_bg {
        // background-image: linear-gradient(#fff, #f5f5f5);
        padding: 0px 10px 10px 10px;
        // border-radius: 30px 30px 0 0;
        position: relative;
        .classify {
          padding: 20px 0;
          .small_classify {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            div {
              display: flex;
              justify-content: center;
              flex-flow: column;
              align-items: center;
              width: 20%;
            }
            .icon {
              width: 30px;
              height: 30px;
              margin: 10px;
            }
          }
          .big_classify {
            display: flex;
            div {
              flex: 1;
              display: flex;
              justify-content: center;
              flex-flow: column;
              align-items: center;
              .icon {
                width: 50px;
                height: 50px;
                margin-bottom: 5px;
              }
            }
          }
        }

        .search {
          position: relative;
          .search_text {
            position: absolute;
            background-color: #ffc400;
            right: -6px;
            top: 1px;
            width: 50px;
            height: 32px;
            border-radius: 16px;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
          }
          input {
            width: 100%;
            border: 1px solid #ffc400;
            border-radius: 20px;
            height: 30px;
          }
        }
      }
    }

    .header {
      //   background-image: linear-gradient(#ffc400, #fff);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 20px 40px 20px;
      .text {
        font-size: 20px;
        font-weight: 600;
      }
      .name {
        width: 100%;
        .shjc {
          display: flex;
          align-items: center; //交叉轴
          justify-content: center; //主线

          font-size: 20px;
          color: white;
        }
      }
    }
  }
  .bah{
      display: flex;
          align-items: center; //交叉轴
          justify-content: center; //主线

          font-size: 14px;
          margin-bottom: 20px;
    color: white;
  }
}
</style>

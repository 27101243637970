<template>
  <div class="item" @click="toStore(item)">
    <div class="top">
      <lazy-component>
      <media :head-data="headData"></media>
        </lazy-component>
      <div
        class="bottom_buy"
        v-if="getPayStatus(item.saleTime, item.balanceTotal) == 'selling'"
      >
        <!-- 立即购买 -->
      </div>
      <div
        class="pre_buy"
        v-if="getPayStatus(item.saleTime, item.balanceTotal) == 'countdown'"
      >
        <span>{{ "即将开售 " + hours + ":" + mins + ":" + second }}</span>
      </div>
      <div
        class="bottom_buy"
        v-if="getPayStatus(item.saleTime, item.balanceTotal) == 'sell_out'"
      >
        <img src="@/assets/soldo_out.png" />
      </div>

      <div
        class="pre_buy"
        v-else-if="getPayStatus(item.saleTime, item.balanceTotal) == 'pre'"
      >
        <span>{{ monthAndDay(item.saleTime) + " " + hourAndmin(item.saleTime) }}</span>
      </div>
    </div>
    <!-- <video class = "v" :src="item.pic" autoplay="autoplay" preload="auto"  loop v-show="!imgIs"/> -->

    <!-- <div class="content1" style="display: none;" v-show="!imgIs"> -->

    <!-- </div> -->
    <!-- <canvas class="ts" id="ts" ></canvas> -->

    <div class="item_right">
      <div class="title">{{ item.title }}</div>
      <div class="sales" v-show="tabChildShow">
        <div class="sales_info">限量</div>
        <div class="sales_data">{{ item.sales }}份</div>
        <div class="sales_source">{{ item.source }}</div>
      </div>
      <div class="bottom_info" v-show="tabChildShow">
        <div class="bottom_left">
          <!-- <div class="bottom_image"> -->
          <img class="image" src="../../../assets/local_icon.png" />
          <!-- </div> -->
          <!-- <div class="bottom_local_source">{{ item.localSource }}</div> -->
          <span>{{ item.localSource }}</span>
        </div>

        <div class="bottom_right">
          <div class="bottom_mark">¥</div>
          <div class="bottom_price">{{ item.price }}</div>
        </div>
      </div>
      <div class="bottom_info1" v-show="!tabChildShow">
        <div class="bottom_local_source">{{ item.info }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRouter} from "vue-router";
import {onUnmounted, ref} from "vue";
import Media from "@/components/Media";

export default {
	components:{Media},
	props: ["item"],
  setup(props) {
    const router = useRouter();
    const hours = ref("00");
    const mins = ref("00");
    const second = ref("00");
    let countDownTimer = ref({});
    const toStore = (item) => {
      // router.push({ path: "./goods", query: { title: item.title } });
      //  router.push({ path: "./store", query: { mid: item.id} });
      console.log("type--" + item.type);
      if (item.type == "0") {
        console.log("单品");
        router.push({ path: "./store", query: { mid: item.id } });
      }
      if (item.type == "1") {
        console.log("系列");
        router.push({ path: "./goods", query: { mid: item.id } });
      }
      if (item.type == "3") {
        console.log("专题");
        router.push({ path: "./topic", query: { mid: item.id } });
      }
    };
    const getIntervalHour = (date1, date2) => {
      if (!date2) {
        return 0;
      }
      date1 = new Date(date1.replace(/-/g, "/"));
      date2 = new Date(date2.replace(/-/g, "/"));
      var diff = date1 - date2;
      if (isNaN(diff)) {
        console.log("无效的时间值");
        return;
      }
      return diff / (1000 * 60 * 60);
    };

    const formatDate = (date) => {
      var seperator1 = "-";
      var seperator2 = ":";
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate =
        date.getFullYear() +
        seperator1 +
        month +
        seperator1 +
        strDate +
        " " +
        date.getHours() +
        seperator2 +
        date.getMinutes() +
        seperator2 +
        date.getSeconds();
      return currentdate;
    };
    const monthAndDay = (date) => {
      date = new Date(date.replace(/-/g, "/"));
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      return month + "月" + strDate + "日";
    };
    const hourAndmin = (date) => {
      date = new Date(date.replace(/-/g, "/"));
      let hours = date.getHours();
      let mins = date.getMinutes();
      if (hours >= 0 && hours <= 9) {
        hours = "0" + hours;
      }
      if (mins >= 0 && mins <= 9) {
        mins = "0" + mins;
      }
      return hours + ":" + mins + "开售";
    };
    const getPayStatus = (saleTime, balanceTotal) => {
      if (balanceTotal == "0") {
        return "sell_out";
      }
      if (getIntervalHour(formatDate(new Date()), saleTime) >= 0) {
        return "selling";
      }
      if (
        getIntervalHour(formatDate(new Date()), saleTime) < 0 &&
        Math.abs(getIntervalHour(formatDate(new Date()), saleTime)) <= 24
      ) {
        return "countdown";
      }
      if (
        getIntervalHour(formatDate(new Date()), saleTime) < 0 &&
        Math.abs(getIntervalHour(formatDate(new Date()), saleTime)) > 24
      ) {
        return "pre";
      }
    };
    const countTime = () => {
      if (!props.item.saleTime) {
        return;
      }
      if (countDownTimer.value) {
        clearTimeout(countDownTimer);
      }
      var now = new Date().getTime();
      var end = new Date(props.item.saleTime.replace(/-/g, "/")).getTime();
      var leftTime = end - now;
      var h, m, s;
      if (leftTime >= 0) {
        h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        m = Math.floor((leftTime / 1000 / 60) % 60);
        s = Math.floor((leftTime / 1000) % 60);
      }
      if (h >= 0 && h <= 9) {
        h = "0" + h;
      }
      if (m >= 0 && m <= 9) {
        m = "0" + m;
      }
      if (s >= 0 && s <= 9) {
        s = "0" + s;
      }
      hours.value = h;
      mins.value = m;
      second.value = s;
      countDownTimer = setTimeout(countTime, 1000);
    };
    onUnmounted(() => {
      if (countDownTimer.value) {
        clearTimeout(countDownTimer);
      }
    });

    return {
      toStore,
      monthAndDay,
      hourAndmin,
      hours,
      mins,
      second,
      countTime,
      countDownTimer,
      formatDate,
      getIntervalHour,
      getPayStatus,
    };
  },
  data() {
    console.log("--1--" + this.item.tabChildShow);
    console.log("--2--" + this.item.imgIs);
    return {
      player: null,
      tabChildShow: this.item.tabChildShow,
      imgIs: this.item.imgIs,
    };
  },
  computed:{
    headData(){
		if(!this.item)return this.item;

	    return {
			"showType":this.item.imgIs ? "img" : 'video',
			//"showIcon":this.item.showIcon,
			"showUrl":this.item.pic,
			"thumbnailPic":this.item.thumbnailPic,
		};
    }
  },
  mounted() {
    if (this.getPayStatus(this.item.saleTime, this.item.balanceTotal) == "countdown") {
      setTimeout(this.countTime, 1000);
    }
  },
};
</script>

<style lang="less" scoped>
.item:not(:first-child) {
}
.item {
  background: rgba(50, 50, 50, 1);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  box-shadow: 0 0 10px #000;
  //   padding: 10px;
  flex-direction: column;
  .top {
    // height: 334px;
    min-height: 250px;
    img {
      width: 100%;
      border-radius: 10px 10px 0 0;
      margin-right: 20px;
    }
    position: relative;
    .bottom_buy {
      z-index: 99999;
      position: absolute;
      border-radius: 10px;
      top: 97px;
      left: 98px;
      img {
        width: 158px;
        height: 161px;
      }
    }
    .pre_buy {
      z-index: 99999;
      position: absolute;
      float: left;
      top: 8px;
      left: 9px;
      width: 140px;
      height: 24px;
      display: flex;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      span {
        text-align: center;
        margin: auto;
        line-height: 24px;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
      }
    }
  }
  .video {
    // border-radius:10px;
  }
  .ts {
    border-radius: 10px 10px 0 0;
    height: 360px;
    width: 100%;
  }
  .v {
    width: 100%;
    // height: 400px;
    border-radius: 10px 10px 0 0;
    margin-right: 20px;
  }

  .item_right {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    .title {
      color: white;
      font-weight: 500;
      font-size: 14px;
      margin-top: 10px;
      margin-left: 10px;
    }
    .sales {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      .sales_info {
        color: rgba(113, 72, 12, 1);
        font-size: 12px;
        line-height: 13px;
        background: linear-gradient(
          90deg,
          rgba(217, 173, 115, 1) 0%,
          rgba(235, 200, 155, 1) 100%
        );
        // border-radius: 18px;
        padding: 2px 5px 2px 5px;
        margin-top: 10px;
        margin-left: 10px;
        z-index: 10;
      }
      .sales_data {
        color: rgba(217, 173, 115, 1);
        font-size: 12px;
        line-height: 13px;
        background: rgba(33, 30, 32, 1);
        // border-radius: 18px;
        padding: 2px 5px 2px 25px;
        margin-top: 10px;
        margin-left: -20px;
      }
      .sales_source {
        color: rgba(113, 72, 12, 1);
        font-size: 12px;
        line-height: 13px;
        background: linear-gradient(
          90deg,
          rgba(217, 173, 115, 1) 0%,
          rgba(235, 200, 155, 1) 100%
        );
        // border-radius: 18px;
        padding: 2px 8px 2px 8px;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
    .bottom_info {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      margin-top: 8px;
      .bottom_left {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 10px;
        margin-top: 10px;
        img {
          height: 15px;
          width: 15px;
        }
        span {
          font-size: 10px;
          height: 15px;
          color: rgba(217, 173, 115, 1);
          display: flex;
          margin-left: 2px;
          margin-top: 1px;
        }
        .bottom_image {
          .image {
            height: 15px;
            width: 12px;
          }
        }
        .bottom_local_source {
          font-size: 10px;
          height: 15px;
          color: rgba(217, 173, 115, 1);
          display: flex;
          margin-left: -15px;
        }
      }
      .bottom_right {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-top: 0px;
        margin-right: 20px;
        align-items: center;
        .bottom_mark {
          font-weight: 500;
          font-size: 25px;
          color: white;
          margin-right: 6px;
        }
        .bottom_price {
          font-weight: 500;
          font-size: 25px;
          color: white;
        }
      }
    }
    .bottom_info1 {
      margin-bottom: 10px;
      .bottom_local_source {
        font-size: 10px;
        color: rgba(128, 128, 128, 1);
        display: flex;
        vertical-align: baseline;
        align-items: center;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
}
</style>
